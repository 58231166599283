import requestResult from '@/common/request/request'

export type WinPeople = {
    name: string;
    type: number; // 类型 1 券 2 积分
    denomination: number; // 值
}

export type GacheItem = {
    type: number; // 1 券 2 积分
    icon: string; //
    denomination: number; // 值
}
export type GacheInfo = {
    startTime: number;
    endTime: number;
    infoModels: WinPeople[];
    status: number; // 0 不在抽奖时间 1 未抽奖 2 已经抽过奖
    gachaInfoModelList: GacheItem[];
}

export type gotInfo = {
    status: number;
    infoModels: GacheItem[];
}
export const checkEvent = requestResult('/api/event/check') // 检测是否有小测试或者抽卡活动
export const readGache = requestResult<GacheInfo>('/api/event/gacha') // 进入抽卡界面
export const goGache = requestResult<gotInfo>('/api/event/gacha/draw') // 抽卡
