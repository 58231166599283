
import { defineComponent, onBeforeUnmount, onMounted, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Clock from '@/events/cardGame/components/TheClock.vue'
import Pic from '@/components/Pic.vue'
import { readGache, goGache } from '@/events/cardGame/cardGame.api'
import GacheLimitDialog from '@/events/cardGame/components/GacheLimitDialog.vue'
import { openDialog, showAlert } from '@/components/popup/popup'
import { useRouter } from 'vue-router'
import { formatDateTime } from '@/common/format'

export default defineComponent({
  name: 'TheCardGame',
  components: { PageWithHeader, Clock, Pic },
  setup () {
    const audio = shallowRef<HTMLAudioElement | null>(null)
    const audioReady = shallowRef(false)
    const router = useRouter()
    const cardInfo = shallowRef()
    const step1 = shallowRef(false)
    const hideInit = shallowRef(false)
    const canDraw = shallowRef(false)
    const showPoint = shallowRef(false)
    const noticeList = shallowRef()
    const gacheTime = shallowRef()
    const timer = {
      timer_1: 0,
      timer_2: 0,
      timer_3: 0,
    }

    // 初始化抽卡信息
    const getActInfo = async () => {
      const res = await readGache()

      gacheTime.value = [
        formatDateTime('HH:mm', res.startTime),
        res.endTime ? formatDateTime('HH:mm', res.endTime) : '--:--',
      ]

      noticeList.value = res.infoModels

      if (res.status === 0) {
        // 活动时间未开始
        openDialog(GacheLimitDialog, {
          path: 'gacha_8',
          // onClose: router.back,
        })
      } else if (res.status === 1) {
        // 可以参与抽奖
        canDraw.value = true
        showPoint.value = true
      } else if (res.status === 2) {
        // 已经抽过奖
        hideInit.value = true
        cardInfo.value = res.gachaInfoModelList

        for (let i = 0; i < cardInfo.value.length; i++) {
          setTimeout(() => {
            document.getElementById('card_' + i)?.classList.add('step3_' + i)
            document.getElementById('card_' + i)?.classList.add('step4_' + i)
          }, 0)
        }
      } else if (res.status === 3) {
        // 用户没有做单
        openDialog(GacheLimitDialog, {
          path: 'gacha_11',
          // onClose: router.back,
        })
      }
    }

    getActInfo()

    // 点击抽卡操作
    const start = async () => {
      if (!canDraw.value || !audioReady.value) {
        return false
      }

      const res = await goGache()
      showPoint.value = false
      cardInfo.value = res.infoModels

      // 确保返回中奖信息为数组并且固定含有三个元素
      if (!Array.isArray(cardInfo.value) || cardInfo.value.length === 0) {
        showAlert('prize list is empty!')
        return false
      } else if (cardInfo.value.length > 3) {
        cardInfo.value = cardInfo.value.slice(0, 3)
      }

      // 执行动画第一步
      step1.value = true
      spread()
    }

    // 卡片展开动画
    const spread = () => {
      timer.timer_1 = window.setTimeout(() => {
        hideInit.value = true
        for (let i = 0; i < cardInfo.value.length; i++) {
          document.getElementById('card_' + i)?.classList.add('step2_' + i)
        }
        setTurn()
      }, 500)
    }

    // 卡片翻转动画
    const setTurn = () => {
      for (let i = 0; i < cardInfo.value.length; i++) {
        timer.timer_2 = window.setTimeout(() => {
          document.getElementById('card_' + i)?.classList.add('step3_' + i)
          setPosition(i)
          if (audio.value) {
            audio.value.currentTime = 0
            audio.value.play()
          }
        }, i * 1000 + 800)
      }
    }

    // 卡片翻转后归位动画
    const setPosition = (i: number) => {
      timer.timer_3 = window.setTimeout(() => {
        document.getElementById('card_' + i)?.classList.add('step4_' + i)
      }, 1000)
    }

    onMounted(() => {
      if (audio.value) {
        audio.value.src = '/media/gacha.mp3'
        audio.value.load()
      }
    })

    onBeforeUnmount(() => {
      clearTimeout(timer.timer_1)
      clearTimeout(timer.timer_2)
      clearTimeout(timer.timer_3)
    })

    return {
      onMediaReady () {
        audioReady.value = true
      },
      audio,
      gacheTime,
      start,
      noticeList,
      step1,
      hideInit,
      showPoint,
      cardInfo,
    }
  },
})
