
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GacheLimitDialog',
  components: { DialogTemplate },
  props: {
    path: String,
  },
  setup (props, ctx) {
    const exitClick = () => {
      ctx.emit('close')
    }
    return {
      exitClick,
    }
  },
})
